.heading {
  font-size: 26px;
  font-weight: 600;
  border: 0;
  width: 100%;
}

.cardTitle {
  font-size: 20px;
  font-weight: 600;
  margin-right: 15px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cardHeaderLeft {
  display: flex;
}

.actionButton {
  z-index: 1;
  width: 100%;
}