.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 700 !important;
  background-color: var(--bg);
  color: var(--font);
  height: 89px;

  position: fixed;
  z-index: 1200;
  top: 50px;
  left: 0;
  right: 0;
  margin-left: 270px;
}

.leftContainer {
  font-size: 26px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 1 auto;
  max-width: 400px; 
}

.minifiedActions {
  display: none;
}

@media only screen and (max-width: 960px) {
  .minifiedActions {
    display: inherit;
  }

  .headerBlock {
    margin-left: 0;
  }

  .rightContainer {
    margin-left: 10px;
    flex: 1 0 auto;
  }
}

.backLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  margin-left: -0.5em;
  color: #666;
  transition: color 0.15s ease;
  &:hover {
    color: rgb(54, 54, 54);
  }
}