.pdfsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pdfsList_item {
  gap: 1rem;
}

.pdfsList_itemDetails {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

.pdfsList_itemDetailsLink {
  text-decoration: none;
  font-weight: 600;
}

.pdfsList_itemActions {
  align-items: center;
  display: flex;
  flex: 0;
  gap: 0.5rem;
}