.titleBar {
  background-color: var(--primary);
  color: white;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  font-size: 14px;
}

.dialogTitle_subTitle {
  opacity: 0.7
}

.planDescription {
  opacity: 0.85;
  display: inline-block;
  margin-left: 0.5rem;
}

.modalContent {
  padding: 0 !important;
  overflow: auto;
}

.planDetailTableWrapper {
  white-space: nowrap;
  
  div > div:nth-child(2) {
    max-height: 80vh;
    overflow: scroll;
  }

  // Hide the expansion button if expansion is disabled
  button[class*='expandDisabled']{
    opacity: 0.35;
    pointer-events: none;
  }
}