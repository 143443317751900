.container {
  padding-top: 89px;
  scroll-padding-top: 89px;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 0;
  padding-left: 270px;
  position: absolute;
  z-index: 1;
}

.containerFooter {
  padding-bottom: 70px;
  scroll-padding-bottom: 70px;
}

.containerHelp {
  scroll-padding-top: 161px;
}

@media only screen and (max-width: 960px) {
  .container {
    padding-left: 0;
  }
}
