.contentGrid {
    margin: 30px;
  }
  
  .placeholder {
    opacity: 0.5;
  }
  
  .datePicker {
    margin: 20px;
    width: 100%;
  }
  
  .textField {
    width: 100%;
  }
  
  .fileDrop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  