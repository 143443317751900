.editPlanForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0.75em 0;

  [class*='Mui-disabled'] {
    opacity: 0.45;
    pointer-events: none;
  }
}

.titleBar {
  background-color: var(--primary);
  color: white;
  font-size: 14px;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
}

.skusList {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5em 0;

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.skuDisabled {
  opacity: 0.45;
  pointer-events: none;
}