.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  padding-bottom: 60px;
}

.gridList {
  width: 100%;
  height: 100%;
  margin: 8px !important;
}

.imageTile {
  // margin: 16px;
  border-radius: 4px;
  // width: 320px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: var(--bg);

  :hover {
    cursor: pointer;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.actionIcon {
  margin-left: 9px;
}

.pagination {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg);
  border: var(--border);
}
