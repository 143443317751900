.mgPageList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  padding: 0;
}

.mgPageList_item {
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 4px;
}

.mgMaterialList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.mgMaterialList__item {
  list-style-type: none;
  margin: 0;
  padding: 0;
}