.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
}

.gridList {
  width: 100%;
  height: 100%;
  margin: 8px !important;
}

.icon {
  color: rgba(255, 255, 255, 0.54);
}

.downloadImage {
  // filter: invert(1);
  width: 20px;
}

.imageTile {
  // margin: 16px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: var(--bg);  
}

.tileRoot {
  width: 200px !important;
  height: 200px !important;
}

.image {
  object-fit: contain;
}

.gridListBarTitle {
  font-size: small !important;
}

.gridListBarSubtitle {
  font-size: x-small !important;
}

.headerRight {
  display: flex;
  flex-direction: row;
}