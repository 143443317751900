.myDisplaysPlanningTable {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  max-height: calc(100vh - 203px);

  [class^='MUIDataTable-responsiveBase'] {
    flex: 1;
    height: 100%;
  }

  table {
    table-layout: auto;
  }

  tr {
    cursor: pointer;
  } 

  th {
    font-weight: 600;
  }

  td, th {
    padding: 0.75em 0.5em;
    text-align: left;

    &:first-child {
      padding-left: 1em;
    }
    &:last-child {
      padding-right: 1em;
    }

    &:nth-child(3) {
      min-width: 180px;
    }
    &:nth-child(4) {
      min-width: 180px;
    }
    &:nth-child(5) {
      max-width: 240px;
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &:nth-child(6) {
      min-width: 100px;
    }
    &:nth-child(7) {
      min-width: 100px;
    }
    &:nth-child(8) {
      min-width: 180px;
    }
    &:nth-child(9) {
      min-width: 120px;
    }
    &:nth-child(10) {
      min-width: 120px;
    }
    &:nth-child(11) {
      max-width: 160px;
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &:nth-child(13) {
      min-width: 80px;
    }
    &:nth-child(15) {
      min-width: 140px;
    }
    

  }

  button[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

}

// Make the action column sticky
.myDisplaysPlanningTable {
  td, th {
    // Exclude the last column from the expanded row
    &:not(.myDisplaysPlanningTable_expanded_row td, .myDisplaysPlanningTable_expanded_row th):last-child {
      background-color: transparent;
      position: sticky;
      right: 0;
      z-index: 100;
    }
  }

  td:last-child:not(.myDisplaysPlanningTable_expanded_row td:last-child) {
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%);
  }
  th:last-child:not(.myDisplaysPlanningTable_expanded_row th:last-child) {
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.95) 8px);
  }
}

.planDescription {
  font-weight: 500;
  color: var(--font);
}

.cellLink {
  cursor: pointer;
  color: var(--primary);
}

.myDisplaysPlanningTableExpandedRow {
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.035));
  // border-bottom: 1px solid #ddd;
  background-color: #fcfcfc;
  background-image: linear-gradient(rgba(0,0,0,0) calc(100% - 10px), rgba(0,0,0,0.05));
  cursor: default;
}

.myDisplaysPlanningTableExpandedRowTable {
  tr {
    cursor: default;
  } 

  th {
    font-weight: 500;
  }

  td, th {
    padding: 0.75em 0.5em;
    text-align: left;
    min-width: 8em;

    &:first-child {
      padding-left: 0;
    }
  }
}