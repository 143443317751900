.contentGrid {
    margin: 30px;
  }
  
  .placeholder {
    opacity: 0.5;
  }
  
  .textField {
    width: 100%;
  }
  
  .card {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .cardTitle {
    font-size: 20px;
    font-weight: 600;
    margin-right: 15px;
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .cardHeaderLeft {
    display: flex;
  }
  
  .buttonStyle {
    float: right;
    animation: moveAcross 1s 1;
  }
  
  .error {
    color: #ff0000;
  }
  
  .actionButton {
    z-index: 1;
  }
  
  .icon {
    height: 22px;
  }
  
  .checkbox {
    margin-right: 20px;
  }
  
  .moreButtonContainer {
    display: flex;
    margin-left: 10px;
  }
  
  .moreButton {
    opacity: 0.9;
    height: 18px;
    transform: rotate(90deg);
    cursor: pointer;
  }
  
  .moreButton:hover {
    opacity: 0.5;
  }
  
  .activeToolbar {
    height: 0;
  }
  
  .inactiveToolbar {
    height: inherit;
  }
  
  .smallScreen {
    display: none;
  }
  
  @media only screen and (max-width: 1950px) {
    .largeScreen {
      display: none;
    }
  
    .smallScreen {
      display: inherit;
      font-size: 16px;
    }
  }
  
  @keyframes moveAcross {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  