.textField {
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.textFieldContainer {
  margin: 20px;
  width: 100%;
}

.routeField {
  width: 100%;
}

.buttonGroup {
  margin: 5px;
}
