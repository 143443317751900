.toolbar {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: var(--border);
}