.contentGrid {
  margin: 30px;
}

.placeholder {
  opacity: 0.5;
}

.datePicker {
  margin: 20px;
  width: 100%;
}

.textField {
  width: 100%;
}

.fileDrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.card {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  align-items: flex-start;
  justify-content: flex-start;
}

.buttonStyle {
  float: right;
  animation: moveAcross 1s 1;
}

.warning {
  color: orange;
}

@keyframes moveAcross {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
