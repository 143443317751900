.red-page {
    margin: 0;
    padding: 0;

}

.edit-button {
    border-radius: 0.5rem;
    border: 1px solid #1e40af;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    background-color: #e2e8f0;
    color: #1e40af;
    font-size: 1rem;
    line-height: 1.5rem;
}

.edit-button:hover {
    background-color: #93c5fd;
}

.cancel-button {
    border-radius: 0.5rem;
    border: 1px solid #dc2626;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    background-color: #fecaca;
    color: #991b1b;
    font-size: 1rem;
    line-height: 1.5rem;
}

.cancel-button:hover {
    background-color: #fca5a5;
}

.save-button {
    border-radius: 0.5rem;
    border: 1px solid #16a34a;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    background-color: #bbf7d0;
    color: #166534;
    font-size: 1rem;
    line-height: 1.5rem;
}

.save-button:hover {
    background-color: #86efac;
}

.create-container {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    height: 100%;
    border-radius: 0.5rem;
    background-color: white;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1.25rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.rule-text {
    font-size: large;
    margin-top: 1rem;
    font-weight: 600;
}

.display-type-list {
    margin: 2.5rem;
}

.display-type-card-container {    
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-auto-rows: max-content;
    gap: 2.5rem;
    margin: 2.5rem
  }

.display-type-card {
    width: 100%;
    border-radius: 0.5rem;
    border-top: 8px solid #dc2626;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-align: left;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
}

.display-type-card:hover {
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.new-display-type-card-container {
    margin: 2.5rem
}

.new-display-type-card {
    width: 18%;
    height: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border-top: 8px solid #dc2626;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.active-tag {
    margin-top: 4rem;
}

.active-tag-text {
    border-radius: 9999px;
    background-color: #bbf7d0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    color: #166534;
    font-weight: 600;
}

.inactive-tag-text {
    border-radius: 9999px;
    background-color: #fca5a5;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    color: #991b1b;
    font-weight: 600;
}

.date {
    color: #9ca3af
}

.date span {
    font-weight: 600;
}

.red-score {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.red-score span {
    color: #dc2626;
    font-weight: 700;
}

.red-score-number {
    font-size: 120px;
    color: #dc2626;
}


.display-input {
    width: 100%;
}

.table-container {
    position: relative;
    overflow-x: auto;
    border-radius: 0.5rem;
}

.table {
    width: 100%;
    text-align: left;
    font-size: 0.825rem;
    line-height: 1.25rem;
    border-radius: 0.5rem;
}

.table thead {
    background-color: #e5e7eb;
    text-transform: uppercase;
}

.table-dropdown {
    cursor: pointer;
    border-radius: 9999px;
    padding: 0.3rem;
    font-size: 2rem;
}

.table-dropdown:hover {
    background-color: #e5e7eb;
}

.modal-create {
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    background-color: white;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.MuiDialog-paper {
    overflow-y: visible;
}

.activity-link {
    color: #2564eb;
    cursor: pointer;
}

.activity-link:hover {
    text-decoration-line: underline;
    color: #3b83f6
}


 /* style applicable only for bulk upload core range */
  .MuiDialog-paper:has(.download-template) {
    overflow-y: hidden !important;
}

/* style applicable only for bulk upload core range */
.MuiDialogContent-root:has(.download-log-file) {
    height: auto !important;
}