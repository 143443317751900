.programmeDetailList {
  list-style-type: none;
  padding: 0.75rem 0;
  border: 1px solid #e5e5e5;
  border-radius: 8px 8px 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.programmeDetailList_extension {
  padding: 0.75rem;
  border: 1px solid #e5e5e5;
  border-top: 0;
  border-radius:  0 0 8px 8px;
}

.programmeDetailList li {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.75;
}

// Formats key data within the list item
.programmeDetailList_primary {
  font-weight: 600;
}

.programmeDetailList_secondary {

}

.programmeDetailList_tertiary {
  color: rgb(92, 92, 92);
}