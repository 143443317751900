.inputFillRemainingSpace {
  display: flex;
  flex-grow: 1;
}

.textFieldBorderRightNone fieldset {
  border-right: none;
}

.textFieldBorderRadiusRightZero fieldset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.textFieldBorderRadiusLeftZero fieldset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.disabledDomain {
  width: 100px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #999;
}
