
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Regular.woff2') format('woff2'),
       url('/fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Medium.woff2') format('woff2'),
       url('/fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
       url('/fonts/Inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Bold.woff2') format('woff2'),
       url('/fonts/Inter/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@import "./Theme.scss";

html {
  height: 100%;
  background-color: var(--bg-2);
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
}

h1 {
  font-size: 2.2em;
  font-weight: 600;
}

h2 {
  font-weight: 600;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-bar {
  opacity: 0;
}

.content {
  background-color: var(--bg-2);
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold;
}