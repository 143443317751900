.core-range-button-group {
    display: flex;
    align-items: center;
    margin-left: 30px
}
.core-range-button-group {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-top: 16px;
}
.core-range-add-button {
    background-color: "#c72221";
    color: "#fff";
    transition: all ease 0.3s;
}
.core-range-add-button:hover {
    background-color: "#d33c3b"
}



@media only screen and (max-width: 1450px) {
    .core-range-target-group {
        display: flex;
        flex-direction: column;
    }
    .core-range-button-group {
        display: flex;
        margin-right: 150px;
    }
}

.rename-door-input {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: medium;
    border-radius: 10px;
    border: #000 2px solid;
}

.rename-door-input:focus {
    border: #c31d1d 2px solid;
}

.rename-door-button {
    background: #cf2727;
    border-radius: 10px;
    outline: none;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    cursor: pointer;
}

.rename-door-button:hover {
    background: #c31d1d;
}

.core-range-active {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 16px;
    background-color: #BBF7D0;
    color: #14532D;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.core-range-inactive {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 16px;
    background-color: #FECACA;
    color: #7F1D1D;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

#table-container {
    display: flex;
    flex-direction: row;
    height: 80vh;
    overflow-x: scroll;
    overflow-y: hidden;
}

#door-container {
    display: flex;
    flex-direction: row;
    height: 80vh;
    overflow-y: scroll;
    overflow-x: scroll;
}

/* #door-container:hover {
    overflow-x: scroll;
} */

/* TODO: Custom MUI theme overrides were being blitzed, this is a temporary workaround */
.MuiPickersDay-current {
    border-radius: 4px !important;
    border: 1px solid #ddd !important;
}
.MuiPickersDay-current * {
    font-weight: bold !important;
}
.MuiPickersDay-daySelected {
    border-radius: 4px !important;
    background-color: var(--primary) !important;
    color: #ffffff !important;
}

/* style applicable only for mass update */
.MuiDialogContent-root:has(.parent-container) {
    height: auto !important;
}

.landmark {
    overflow-y: auto;
    background: rgba(247, 247, 247, 0.75);
    border-radius: 5px;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
    font-family: Helvetica, Arial, sans-serif;
    -webkit-transform-origin: 0 10px;
    transform-origin: 0 10px;
}

.landmark .title-element h2{
    margin-top: 0;
    padding: 5px 10px;
    color: #fff;
    font-size: 16px;
}
.inner-container h1{
    color: #fff;
    font-size: 16px;
}

.landmark h1 {
    margin-top: 0;
    padding: 5px 10px;
    color: #000;
    font-size: 16px;
    font-weight: 300;
}

.landmark h2 {
    margin-top: 0;
    padding: 5px 10px;
    color: #000;
    font-size: 16px;
}

.landmark section {
    padding: 0 15px 5px;
    font-size: 14px;
}

.landmark section p {
    margin: 5px 0;
}

@-webkit-keyframes scale-and-fadein {
    0% {
        -webkit-transform: scale(0.2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-and-fadein {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.landmarkPopup {
    display: flex;
    justify-content: space-between;
}

.element-background {
    background-color: #ecf1fb;
    border: 1px solid lightgray;
}

.element-white-background{
    background-color: #fff;
}
.title-element {
    display: flex;
    justify-content: space-between;
    background-color: #c31d1d;
}

.landmark .title-element h1 {
    margin-top: 0;
    padding: 5px 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
}

#map-header .MuiIconButton-root {
    color: #fff;
}

#map-header .MuiFormLabel-root {
    color: #fff;
}

#map-header .MuiInputBase-root {
    color: #fff;
}
#map-header .MuiButton-root {
    color: #fff;
}

.container {
    margin-top: 0px;
    justify-content: space-between;
    position: fixed;
    height: 100px;
    left: 0;
    right: 0;
    z-index: 1200;
    display: flex;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
    background-image: linear-gradient(-53deg, white 0%, white 100%);
}

.outer-container {
    margin-top: 100px;
}

.appBar {
    background-color: #EADDCA !important;
    justify-content: center;
    height: 50px;
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}

.toolbar {
    min-height: 50px;
    display: 'flex';
    justify-content: 'space-between';
}

.right-text {
    flex-grow: 1;
    text-align: right;
}

.inner-text {
    font-size: 0.875rem;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 600;
}

.inner-container {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-grow: 1;
}

.legend {
    position: fixed;
    top: 42%;
    left: 96%;
    transform: translate(-50%, -50%) scaleY(-1) rotate(230deg);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 34px;
    cursor: pointer;
}

@media (max-width: 846px) {
    .legend {
        top: 48%;
    }
}

.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
}

.modal {
    position: absolute;
    top: 56%;
    left: 89%;
    transform: translate(-50%, -50%);
    padding: 4;
}

.modal-container {
    width: 80%;
    max-width: 300px;
    background-color: white;
    right: 100px;
    outline: none;
}

@media (max-width: 846px) {
    .modal-container {
        top: 66%;
        left: 83%;
    }
}

.row {
    border-bottom: 1px solid #ccc;
    padding: 12px;
    margin-bottom: 5px;
    width: 100%;
    text-align: flex;
    font-weight: bold !important;
}

.title {
    background-color: #c31d1d;
    color: white;
    font-weight: bold !important;
    font-size: large !important;
    text-align: center !important;
}
