.appBarButton {
  height: 100%;
  padding: 0 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: #fff;
}

.appBarButton:hover {
  background-color: var(--accent-2);
}

.inactive {
  opacity: 0;
  display: none;
}

.active {
  opacity: 1;
  display: inherit;
}

.accountPopover {
  position: fixed;
  top: 50px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;
}

.accountPopoverContainer {
  height: 100%;
}

.background {
  margin-top: 50px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
}

.background.hidden {
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.background.showing {
  opacity: 0.2;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.icon {
  height: 30px;
  widows: 30px;
}